<template>
  <div class="predictframe" id="predict">
    <div class="background-img"></div>
    <div class="inputframe" v-if="inputordraw">
      <div class="center">
        <h1 style="font-size: 32pt; color: white">Activity Predicting</h1>
      </div>
      <div class="center">
        <div class="center" style="
            margin-top: 28px;
            width: 1156px;
            height: 60px;
            margin-left: 150px;
          ">
          <input class="text input2" placeholder="Please enter the molecular fomula"
            v-model="molecularFormula" /><button class="text button2" onmouseover="this.className='button2 text2'"
            onmouseout="this.className='button2 text'" @click="gotoinputordraw">
            Draw
          </button>
          <select class="select" @change="setmode2">
            <option v-for="item in modeArr" :key="item.mode" :value="item.name">
              {{ item.name }}
            </option>
            <option selected hidden disabled value="">Classification</option>
          </select>
          <el-button type="text" class="button3" @click="gotobatch">Batch Predict >></el-button>
        </div>
      </div>
      <div class="center">
        <img style="width: 1006px; margin-top: 22px" src="../assets/singleprediction/photo1.png" />
      </div>
      <div class="center">
        <el-button class="button1" @click="onSubmit">Submit</el-button>
      </div>
    </div>
    <div class="drawframe" v-else>
      <div class="center" style="width: 100%">
        <div class="center" style="position: relative; width: 700px">
          <div style="
              position: absolute;
              left: 0;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
            ">
            <img src="../assets/singleprediction/arrow2.png"
              style="height: 20pt; transform: rotate(180deg); cursor: pointer" @click="gotoinputordraw" />
          </div>
          <h1 style="font-size: 32pt; color: white">Activity Predicting</h1>
        </div>
      </div>
      <div class="center" style="margin-top: 38px; height: 333px">
        <iframe src="./static/jsme.html" frameborder="0" height="340px" width="450px"></iframe>
        <div style="
            display: flex;
            flex-direction: column;
            margin-left: 80px;
            justify-content: space-between;
          ">
          <h1 style="font-size: 20pt; color: white">Classification</h1>
          <div class="input" v-for="item in modeArr" :key="item.mode">
            <input :id="item.mode" type="radio" @click="setmode(item.name)" name="radio" />
            <label class="text1" @click="setmode(item.name)" :for="item.mode">
              {{ item.name }}
            </label>
          </div>
        </div>
      </div>
      <div class="center">
        <img style="width: 1006px; margin-top: 38px" src="../assets/singleprediction/photo1.png" />
      </div>
      <div class="center">
        <button class="button1" @click="onSubmit">Submit</button>
      </div>
    </div>
    <predict-popper v-show="showPopper" :infos="popperInfo" @onSubmit="onSubmitPopper" @onCancel="onCancelPopper">
    </predict-popper>
  </div>
</template>

<script>
import { activityApi } from "../api/index";
import popper from "../components/popper/popper";

export default {
  name: "APinput",
  data() {
    return {
      molecularFormula: "",
      mode: "virus_cell",
      ifshow: false,
      score: {},
      modelinfo: {},
      smilesinfo: {},
      taskName: null,
      inputordraw: 1, //1为输入 0为画分子
      modeArr: [
        { name: "Cancer Cell Lines", mode: "cancer_cell" },
        { name: "NCI60 Cancer Cells", mode: "nci60_cancer_cell" },
        { name: "Virus Target", mode: "virus_target" },
        { name: "Virus Cell Lines", mode: "virus_cell" },
        { name: "Anti-cancer Targets", mode: "cancer_target" },
        { name: "Kinases", mode: "kinase" },
      ],
      showPopper: false,
      popperInfo: {},
      serviceTime: this.$store.getters.getServicePredictTimes,
    };
  },
  created() {
    window.getFromIframe = this.getFromIframe;
  },
  components: {
    "predict-popper": popper,
  },
  methods: {
    gotoinputordraw() {
      this.inputordraw = !this.inputordraw;
    },
    gotobatch() {
      this.$router.replace("/service/activity-prediction/batch");
    },
    setmode(data) {
      this.mode = data;
    },
    setmode2(event) {
      console.log(event.target.value)
      this.mode = event.target.value;
      console.log(this.mode);
    },
    draw() {
      this.ifshow = !this.ifshow;
      this.molecularFormula = "";
      document.getElementById("mfinput").disabled =
        !document.getElementById("mfinput").disabled;
    },
    getFromIframe(value) {
      this.molecularFormula = value;
    },
    checkForm() {
      //检查登陆
      if (!this.$store.getters.getToken) {
        this.$store.commit("alertMsg", {
          msg: "Please login first!",
          type: "error",
        });

        this.$router.push({
          path: "/account/sign-in",
          query: { redirect: this.$route.path },
        });
        return false;
      }
      //检查输入是否为空
      if (
        !this.molecularFormula ||
        this.molecularFormula == "" ||
        this.molecularFormula.trim() == ""
      ) {
        this.$store.commit("alertMsg", {
          msg: "Empty molecular formula.",
          type: "error",
        });
        return false;
      }
      return true;
    },
    // 获取预测结果分数
    getScore() {
      console.log("taskname:", this.taskName);
      activityApi
        .getActivityScore({
          mode: this.mode,
          smiles: this.molecularFormula,
          title: this.taskName,
        })
        .then((res) => {
          //获取成功后进一步获取模型预测分数
          if (res.code == 200) {
            this.$store.commit("alertMsg", {
              msg: "Submit sucessfully",
              type: "ok",
            });
            // 重定向到历史结果页
            setTimeout(() => {
              this.$router.push({
                path: "/user/activity_history",
              });
            }, 1200);
          } else if (res.code == 401) {
            this.$store.commit("alertMsg", {
              msg: "Please login first!",
              type: "error",
            });
            this.$router.push({
              path: "/account/sign-in",
              query: { redirect: this.$route.path },
            });
          } else {
            this.$store.commit("alertMsg", {
              msg: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
    onSubmit() {
      if (this.checkForm()) {
        this.popperInfo = {
          Smiles: this.molecularFormula,
          Classification: this.getModeName(this.mode),
          "Available Time": this.serviceTime,
        };
        this.showPopper = true;
      }
    },
    predict() {
      this.getScore();
    },
    onSubmitPopper(taskName) {
      this.taskName = taskName;
      this.predict();
      this.showPopper = false;
    },
    onCancelPopper() {
      this.showPopper = false;
      console.log("oncancel");
    },
    getModeName(mode) {
      for (let i = 0; i < this.modeArr.length; i++) {
        if (this.modeArr[i].name == mode) return this.modeArr[i].mode;
      }
    },
  },
};
</script>


<style scoped>
.predictframe {
  /* background: linear-gradient(var(--color1-light), var(--color1-dark)); */
  height: 100vh;
  display: flex;
  /* background-size: 100% 100%; */
  flex-direction: column;
  justify-content: center;
}

.background-img {
  z-index: 1;
  background-image: url(../assets/singleprediction/background.png);
  background-size: 60vmax;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.drawframe {
  display: flex;
  z-index: 1;
  flex-direction: column;
}

input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

input[type="radio"]+label::before {
  content: "\a0";
  /*不换行空格*/
  display: inline-block;
  vertical-align: 0.2em;
  width: 14px;
  height: 14px;
  margin-right: 19px;
  border-radius: 3px;
  background-color: whitesmoke;
  text-indent: 0.15em;
  line-height: 0.65;
  /*行高不加单位，子元素将继承数字乘以自身字体尺寸而非父元素行高*/
}

input[type="radio"]:checked+label::before {
  content: "\2713";
  background-color: skyblue;
}

.text1 {
  font-size: 14pt;
  color: white;
}

.select {
  width: fit-content;
  border: none;
  font-size: 16pt;
  font-weight: bold;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: white url(../assets/singleprediction/arrow1.png) 95% center no-repeat;
  background-size: 15px;
  padding-right: 30px;
  padding-left: 17px;
  outline: none;
  border-left: thin solid skyblue;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.button3 {
  height: 60px;
  cursor: pointer;
  font-size: 14pt;
  color: white;
  width: 150px;
  padding-left: 15px;
}

.select option {
  font-size: 14pt;
  font-weight: bold;
}

.input2 {
  width: 711px;
  padding-left: 18px;
  border: none;
  outline: none;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.button2 {
  width: 95px;
  background: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.inputframe {
  display: flex;
  z-index: 1;
  flex-direction: column;
}

.text {
  color: gray;
  font-size: 20pt;
}

.text2 {
  color: #8bbbed;
  font-size: 20pt;
}

.button1:hover {
  background: linear-gradient(to left, #8bbbed, #e8f4ff);
}

.button1 {
  margin-top: 22px;
  width: 327.5px;
  height: 47px;
  color: black;
  font-weight: bold;
  font-size: 20pt;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(to right, #8bbbed, #e8f4ff);
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>